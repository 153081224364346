
<template>
  <div style="border: 1px solid #ccc;">
      <Toolbar
          style="border-bottom: 1px solid #ccc"
          :editor="editor"
          :defaultConfig="toolbarConfig"
          :mode="mode"
      />
      <Editor
          style="height: 180px; overflow-y: auto;"
          v-model="html"
          :defaultConfig="editorConfig"
          :mode="mode"
          @onCreated="onCreated"
      />
  </div>
</template>

<script>
import Vue from 'vue';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import { mapState } from 'vuex';

export default Vue.extend({
  components: { Editor, Toolbar },
  data() {
    return {
      editor: null,
      html: this.roomIntroduce,
      toolbarConfig: {},
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          uploadImage: {
            server: 'https://www.landofpromise.co:8081/lop_project/api/room/single/file',
            fieldName: 'mulFile',
            customInsert(res, insertFn) {
              insertFn(res.data);
            },
          },
        },
      },
      mode: 'simple', // or 'simple'
    };
  },
  computed: {
    ...mapState({
      roomIntroduce: 'roomIntroduce',
    }),
  },
  methods: {
    onCreated(editor) {
      this.html = this.roomIntroduce;
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
  },
  beforeDestroy() {
    const { editor } = this;
    if (editor === null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
});
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
